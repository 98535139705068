.Tags {
  background-color: #282c34;
  max-height: 50vh;
  padding-top: 10px;
  height: 50vh;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px;
}


.Rows {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1;
}

.Tags hr {
    width: 100%;
}

.TagContainer {
  flex: 1;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: left;
  max-height: 500px;
}

.Word {

}
.Tag {
    color: green;
}
.Description {
    color: orange;
}
.Example {
    color: gray;
}

#logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 43px;
    width: 100%;
    text-align: center;
    background-color: rgb(69 69 69/25%);
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: hidden;
}
#logo {
    background-image: url("/src/media/datavoltage.png");
    width: 330px;
    height: 22px;
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}
.codeContainer {
    height: 100%;
    background: black;
}
.save__controls {
    background-color: black;
    color: white;
    z-index: 10000;
    position: absolute;
}
.layoutflow {
}
.OverlayApp {
    font-family: sans-serif;
    text-align: center;
    background: rgba(0,0,0,.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlayform {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    margin-top: 150px;
}

.form .form__custom-button {
  margin-top: 50px;
}

.disabledTab {
    color: white;
}
.selectedTab {
    background: #007acc;
}

.saveModal {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    height: 100%;
}

.MuiFilledInput-root {
    background-color: #fbfbfbb8 !important;
}

.MuiInputBase-root {

}

.codeButtons {
    text-align: center;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.AnalysisRow {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden;
}
.AnalysisCol {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.analysis {
    border-color: white;
    border-style: solid;
    border-width: 3px;
    text-align: center;
    height: 90%;
    position: fixed;
    top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,.5);
    right: 25px;
    left: 25px;
    margin-left: auto;
    margin-right: 800px;
    z-index: 1000000;
}
.indent {
    padding-left: 20px;
}
.end {
    font-weight: bold;
}
.subject {
    color: blue;

}
.verb {
    color: red;
}
.what {
    color: green;
}
.preposition {
    color: orange;
}
.target {
    color: purple;
}
.scene {
    font-size: 22px;
}
.option {
    font-weight: bold;
}
.statementPhrase {
    padding: 5px;
}
.statement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.statementItem {
    flex: 1;
}

.nodeBack
{
    border: hidden;
    height: 50px;
    width: 100px;
    border-radius: 20px;
}
.App {
  position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden;
}

.resize-box {
    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 1000000;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    left: 20px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=) #cccccc no-repeat 0 0;
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: sw-resize;
    transform: scaleX(-1);
}

.controlPanel {
      text-align: center;
      height: 100%;
      overflow-y: hidden;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 100;
}

.AppFlex {
    /* width: 800px; */
    background: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: hidden;
}

.AppSegment {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.StyleEditor {
    width: 800px;
    background: #252526;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 130px;
}


.react-flow__minimap {
    background-color: black !important;
}

.StyleEditorRow {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin: 10px;
    width: 100%;
}

.styleItem {
    padding: 10px;
    justify-content: right;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.AppHolder {
    background-color: #007acc;
    position: relative;
    height: 100%;
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    text-align: left;
}
.AppRight {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

}
.AppLeft {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.labelSpan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #000;
  padding: 0.5em 0.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
